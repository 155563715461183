<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Hojas de inspección"
      subtitle="| Administrador de hojas de inspección"
      class="heading-block"
    >
        <template #extra>
            <div class="mt-5 mt-sm-0 ml-sm-3">
              
            </div>
        </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row>
          <b-col sm="6" xl="2">
            <b-dropdown
              id="dropdown-default-light"
              variant="light"
              text="Acciones agrupadas"
            >
              <b-dropdown-item class="font-size-sm"
                >Habilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm"
                >Deshabilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-size-sm"
                >Eliminar Seleccion</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" class="ml-3">
            <!-- Form Inline - Alternative Style -->
            <b-form inline>
              <b-form-input
                type="search"
                class="form-control-alt mr-sm-2 mb-sm-0"
                id="example-if-email2"
                name="example-if-email2"
                placeholder="Búsqueda"
                v-model="filter"
              ></b-form-input>
            </b-form>
            <!-- END Form Inline - Alternative Style -->
          </b-col>

          <b-col>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right"
              right
            >
              <template #button-content>
                <i class="si si-settings"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="/clients.csv" download
                >Exportar Clientes a Excel</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="mr-1 float-right"
              right
            >
              <template #button-content>
                <i class="fas fa-fw fa-filter"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Nombre</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Departamento</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <br />
        <div class="table-responsive">
          <b-table :items="hojasInspeccion" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
              <template #cell(actions)="row">
                  <!--<b-button size="sm" @click="EditCliente(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                      <i class="fa fa-fw fa-pencil-alt"></i>
                  </b-button>-->
                   <b-button size="sm" @click="EditarHojaInspeccion(row.item.quote_id)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                      <i class="fa fa-fw fa-pencil-alt"></i>
                  </b-button>
                  <b-button size="sm" @click="EliminarHojaInspeccion(row.item.id)"  class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                      <i class="fa fa-fw fa-times"></i>
                  </b-button>
              </template>
          </b-table>
        </div>
        <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import HojaInspeccion from "../../../api/admin/hoja_inspeccion/Hoja_Inspeccion";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      hojasInspeccion:[],
      filter: null,
      view_cliente:false,
      edit : false,
      fields: [
        {key: 'id',sortable: true, label: '# Hoja Inspección'},
        {key: 'quote.id',sortable: true, label: '# de Cotización'},
        {key: 'quote.estado',sortable: true, label: 'Estado de la cotización'},
        {key: 'quote.client.nombre_comercial',sortable: true, label: 'Cliente'},
        {key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      
    };
  },
  computed: {
    totalRows() {
      return this.hojasInspeccion.length
    }
  },
  methods: {

    AllIsnpectionSheets(){
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      HojaInspeccion.GetAllHojaInspeccion(auth)
      .then((response) => {
        console.log(response.data)
        this.hojasInspeccion = response.data;
        this.showoverlay = false;
      })
      .catch((error) => {
        console.log(error.data);
      });
    },

    EditarHojaInspeccion(id){
      this.$router.push({
        path: "/hoja-de-inspeccion/edit/"+id,
        params:{
            id: id,
        }
      });
    },

    EliminarHojaInspeccion(id){
      const context = this;
      let auth = JSON.parse(localStorage.autentication);

      HojaInspeccion.DeleteHojaInspeccion(id,auth)
      .then((response) => {
        Swal.fire({
          title: "Genial!",
          text: response.data.message,
          icon: "success",
        });
        context.GetQuoteInfo(context.quote_id)
      })
      .catch((error) => {
        const obj = error.response.data.errors;
        Object.entries(obj).forEach( elements => 
          Swal.fire({
            title: "Que mal!",
            text: elements[1][0],
            icon: "error",
          })
        );
      });

      context.AllIsnpectionSheets()
    },
   
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },


  },

  mounted() {
    let me = this;
    me.AllIsnpectionSheets();
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
